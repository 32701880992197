<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        lg="6"
      >
        <b-form-group
          :label="$t('models.dreamer')"
          label-for="dreamer"
        >
          <v-select
            v-model="linked_dreamers"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            multiple
            label="name"
            :options="group.dreamers"
          />
        </b-form-group>

 
      <!-- Action Buttons -->
      <b-button
          v-if="group.id != 0"
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          type="button"
          @click="submitChanges"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        >
          {{ $t('forms.save_changes') }}
        </b-button>
        <b-button
          variant="outline-secondary"
          type="button"
          @click="hasHistory() 
            ? $router.go(-1) 
            : $router.push({ 
              name: 'school-groups-view', 
              params: { id: school.id,
                group_id: group.id, } })"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        >
          {{ $t('forms.go_back') }}
        </b-button>
      </b-col>
      <b-col
        cols="12"
        lg="6"
      >
      </b-col>
    </b-row>   
  </div>
</template>

<script>
import { editSchoolGroup } from '@/api/routes'
import {
  BButton, BRow, BCol, BFormGroup,BFormInvalidFeedback, BFormInput, BForm, BCard, BCardHeader, BCardTitle,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import vSelect from 'vue-select'

export default {
  components:{  
    vSelect,
    ValidationProvider, ValidationObserver,
    BButton, BRow, BCol, BFormGroup,BFormInvalidFeedback, BFormInput, BForm, BCard, BCardHeader, BCardTitle,
  },
  setup(props) {
    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    return {
      // form validation
      refFormObserver,
      getValidationState,
    }
  },
  props:{
    school: {
      type: Object,
      required: true
    },
    group: {
      type: Object,
      required: true,
    },
  },
  data(){
    return {
      required,
      linked_dreamers: []

    }
  },
  mounted(){
    let lu = []
    this.group.dreamers.map(x=> {
      if(x.linked){
        lu.push(x)
      }
    })
    this.linked_dreamers = lu
  },
  methods:{
    hasHistory () { 
       return window.history.length > 2 
    },
    async submitChanges(){
      let data = {
          'id': this.group.id,
          'linked_dreamers':this.linked_dreamers,
          'tab':'dreamers',
      }
      await this.$http.post(editSchoolGroup, data).then( response => {
        // console.log(response)
        if(response.data.status == 200){
          this.$emit('refetch-data')
          this.makeToast('success', this.$t('Success'), this.$t('forms.update_success'));
        } else {
          this.makeToast('danger', this.$t('Error'), response.data.msg);
        }
      }).catch(error => {
          // console.log("Err -> ", error);
          this.makeToast('danger', this.$t('Error'),this.$t('error_retry_later'));
      })
    },
    makeToast(variant = null, tit ='',msg = '') {
      this.$bvToast.toast(msg, {
        title: tit,
        variant,
        solid: false,
      })
    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>